exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-classes-schedule-jsx": () => import("./../../../src/templates/classes-schedule.jsx" /* webpackChunkName: "component---src-templates-classes-schedule-jsx" */),
  "component---src-templates-contacts-jsx": () => import("./../../../src/templates/contacts.jsx" /* webpackChunkName: "component---src-templates-contacts-jsx" */),
  "component---src-templates-enroll-school-jsx": () => import("./../../../src/templates/enroll-school.jsx" /* webpackChunkName: "component---src-templates-enroll-school-jsx" */),
  "component---src-templates-index-page-jsx": () => import("./../../../src/templates/index-page.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */),
  "component---src-templates-plans-jsx": () => import("./../../../src/templates/plans.jsx" /* webpackChunkName: "component---src-templates-plans-jsx" */),
  "component---src-templates-policy-jsx": () => import("./../../../src/templates/policy.jsx" /* webpackChunkName: "component---src-templates-policy-jsx" */),
  "component---src-templates-school-rules-jsx": () => import("./../../../src/templates/school-rules.jsx" /* webpackChunkName: "component---src-templates-school-rules-jsx" */),
  "component---src-templates-sunday-club-jsx": () => import("./../../../src/templates/sunday-club.jsx" /* webpackChunkName: "component---src-templates-sunday-club-jsx" */),
  "component---src-templates-teachers-groups-jsx": () => import("./../../../src/templates/teachers-groups.jsx" /* webpackChunkName: "component---src-templates-teachers-groups-jsx" */),
  "component---src-templates-thank-you-jsx": () => import("./../../../src/templates/thank-you.jsx" /* webpackChunkName: "component---src-templates-thank-you-jsx" */)
}

